import * as palette from '~styles/palette.scss'
import {
  dblClickTimer,
  resetDoubleClick,
  handleLegendDblClick,
  handleAllHiddenSeries,
} from '~utils/chart/chartLineLegend'

export const MIDDLE_DASH = '—'

export const TREND_ROUNDING_THREHOLD = 0.00049

export const CACHE_TIME = 60 * 60 * 1000

export const FORMATS = {
  EXCEL: 'excel',
  PPT: 'ppt',
  BOTH: 'both',
}

export const FORMAT_EXTENSIONS = {
  ppt: 'xslx',
  excel: 'pptx',
}

// Error texts
export const LEADING_SPACE_ERROR = "Name can't start with a space"
export const DUPLICATE_ERROR = 'Name should be unique'

export const MODULES = {
  KPI_TRACKING: {
    title: 'KPI Perfomance',
    key: 'kpi_tracking',
    value: 'KPI Tracking',
  },
  CUSTOM_DASHBOARD: {
    title: 'Custom Dashboard',
    key: 'custom_dashboard',
    value: 'Custom Dashboard',
  },
}

export const EXPORT_STATUS = {
  DOWNLOADED: 'downloaded',
  READY: 'ready',
  PROCESSING: 'processing',
  FAILED: 'failed',
}

export const METRIC_TYPES = {
  PERCENT: 'percent',
  SCORE_5: 'score5',
  SCORE_10: 'score10',
  NPS: 'nps',
}

export const LABELS = {
  COMPETITIVE_SET: 'Competitive Set',
  MARKET_BENCHMARK: 'Market Benchmark',
  TOTAL: 'Total',
}

export const EMPTY_SELECT_VALUE = { label: '', value: '' }

export const PERIOD_TABS = [
  { label: 'Week', value: 'Week' },
  { label: 'Month', value: 'Month' },
  { label: 'Quarter', value: 'Quarter' },
]

export const SERIES_COLORS = [
  palette.green50,
  palette.softred,
  palette.purple,
  palette.lightblue,
  palette.green,
  palette.yellow,
  palette.violet,
  palette.flatblue,
  palette.pink,
  palette.softmagenta,
  palette.brown,
  palette.darkgreen,
  palette.blue,
  palette.purple30,
  palette.pinkgold,
  palette.teal,
]

export const SERVE_SERIES_COLORS = [palette.gray60]

export const CHART_WITHOUT_ANIMATION = {
  plotOptions: {
    series: {
      animation: false,
    },
  },
}

export const CHARTS_THEME = {
  global: {
    useUTC: false,
  },
  chart: {
    selectionMarkerFill: 'rgba(54, 173, 134, 0.3)',
    time: {
      timezoneOffset: 5 * 60,
    },
    style: {
      fontFamily: 'inherit',
    },
  },
  title: false,
  // colors: [palette.green50, palette.gray500],
  plotOptions: {
    spline: {
      // color: palette.negative50,
      lineWidth: 3,
      shadow: {
        color: palette.white,
        offsetX: 0,
        offsetY: 0,
        width: 4,
        opacity: 1,
      },
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 2,
        lineWidth: 1,
        lineColor: palette.white,
        // fillColor: palette.green60,
      },
      states: {
        hover: {
          lineWidthPlus: 0,
          halo: {
            attributes: {
              // fill: palette.negative50,
              stroke: palette.white,
              'stroke-width': 2,
            },
            opacity: 1,
            size: 8,
          },
        },
      },
    },
    column: {
      borderRadius: 2,
      states: {
        hover: {
          // opacity: 1,
          // color: palette.green60,
          brightness: false,
        },
        inactive: {
          enabled: false,
        },
      },
    },
  },
  xAxis: {
    // crosshair: true,
    lineColor: palette.gray200,
    labels: {
      autoRotation: false,
      style: {
        color: palette.gray500,
        lineHeight: '16px',
        fontSize: '12px',
        fontWeight: 500,
      },
    },
  },
  yAxis: {
    title: false,
    gridLineColor: palette.gray200,
    // gridLineDashStyle: 'longdash',
    labels: {
      style: {
        color: palette.gray500,
        lineHeight: '16px',
        fontSize: '12px',
      },
      format: '{value} %',
    },
  },
  legend: {
    itemDistance: 24,
    useHTML: true,
    align: 'left',
    symbolPadding: 8,
    itemStyle: {
      color: palette.gray600,
      fontSize: '14px',
      fontWeight: 'normal',
      verticalAlign: 'middle',
    },
  },
  tooltip: {
    shadow: false,
    borderWidth: 0,
    padding: 0,
    backgroundColor: false,
    valueSuffix: ' %',
    style: {
      fontSize: '14px',
      color: palette.gray300,
      padding: '13px 19px',
    },
    shared: true,
    useHTML: true,
  },
  credits: {
    enabled: false,
  },
}

// define object for legend double-click event
export const LEGEND_DBCLICK = {
  plotOptions: {
    series: {
      events: {
        legendItemClick(e) {
          if (dblClickTimer.clickedOnce === true && dblClickTimer.timer) {
            e.preventDefault()
            resetDoubleClick()
            handleLegendDblClick(e, this, this.index)
          } else {
            handleAllHiddenSeries(this.chart.series)
            dblClickTimer.clickedOnce = true
            dblClickTimer.timer = setTimeout(() => {
              resetDoubleClick()
            }, dblClickTimer.timeBetweenClicks)
          }
        },
      },
    },
  },
  legend: {
    useHTML: false,
    className: 'legend--dbclick',
  },
}

export const EVENT_KEYS = {
  REPORT_EXPORT_SUCCESS: 'report_export_success',
  REPORT_EXPORT_ERROR: 'report_export_error',
  ADD_TOAST_TO_STACK: 'add_toast_to_stack',
  CLOSE_TOASTS_BY_TAG: 'close_toasts_by_tag',
}

export const PERIOD_FORMAT = {
  week: { format: 'L/d/yyyy', plus: { value: 6, key: 'days' } },
  month: { format: 'LLL yyyy' },
  quarter: { format: 'Qq yyyy' },
}

export const BACKUP_LOGO_NAME = 'logo_not_found'

export const BRAND_LOGO_SIZES = {
  SM: 36,
  DEFAULT: 40,
  MD: 48,
  LG: 56,
  XL: 60,
  XXL: 88,
  XXXL: 128,
}

export const ABBREVIATIONS = {
  T: 10 ** 12,
  B: 10 ** 9,
  M: 10 ** 6,
  K: 10 ** 3,
}
