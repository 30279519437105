import React, { memo } from 'react'
import { DateTime } from 'luxon'
import cn from 'classnames'
import PropTypes from 'prop-types'

import { HelpCenter } from '../HelpCenter'
import * as s from './style.module.scss'

const Footer = ({ variant, guide, videoSettings, hasHelpCenter = true }) => (
  <>
    <div className={cn('container', s.wrap, { [s[variant]]: variant })}>
      <div className={s.footer}>
        <p>
          © {DateTime.local().year} Sense360 by Medallia. All Rights Reserved.
        </p>
        <ul className={s.menu}>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sense360.com/terms-and-conditions"
            >
              Terms and Conditions
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sense360.com/privacy-policy"
            >
              Privacy Policy
            </a>
          </li>
        </ul>
      </div>
    </div>

    {hasHelpCenter && (
      <HelpCenter guide={guide} videoSettings={videoSettings} />
    )}
  </>
)

Footer.propTypes = {
  variant: PropTypes.any,
  guide: PropTypes.any,
  videoSettings: PropTypes.any,
  hasHelpCenter: PropTypes.any,
}

export default Footer

// TODO: use app-wide
export const MemoedFooter = memo(Footer)
